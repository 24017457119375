<template>
  <v-row class="d-inherit">
    <v-col>
      <div class="h-100">
        <div v-if="pending" class="px-3 mt-4 h5">Yuklanmoqda...</div>

        <template v-else>
          <table class="custom-table-wrapper">
            <thead>
              <tr>
                <td class="ct__td td__index">№</td>

                <td class="ct__td flex-1">Йил</td>

                <td class="ct__td flex-1">Савдо</td>

                <td class="ct__td flex-1">Харажат</td>

                <td class="ct__td flex-1">Фойда</td>

                <td class="ct__td flex-1">Фойда % хисобида</td>

                <td class="ct__td flex-1">
                  Олдинги йилга нисбатан савдонинг ошиши
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in fields" :key="index">
                <td class="ct__td td__index">{{ index + 1 }}</td>
                <td class="ct__td flex-1">{{ item.year }}</td>
                <td class="ct__td flex-1">
                  {{ numberWithSpaces(item.income) }}
                </td>
                <td class="ct__td flex-1">
                  {{ numberWithSpaces(item.outcome) }}
                </td>
                <td class="ct__td flex-1">
                  {{ numberWithSpaces(item.profit) }}
                </td>
                <td class="ct__td flex-1">
                  {{ numberWithSpaces(item.profit_percent) }}
                </td>
                <td class="ct__td flex-1">
                  {{ numberWithSpaces(item.year_profit_percent) }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { numberWithSpaces } from '@/utils'

export default {
  data() {
    return {
      filterFormProps: {},

      pending: false
    }
  },

  computed: {
    ...mapGetters(['filteredReport']),
    ...mapState({
      fields: (state) =>
        ((state.reportsStore || {}).reportsData || {}).results || {}
    })
  },

  methods: {
    ...mapActions(['report31']),

    numberWithSpaces,

    async fetchData() {
      this.pending = true

      try {
        await this.report31({
          params: {
            ...(this.filterFormProps || {})
          }
        })
      } catch (err) {
        //
      }

      this.pending = false
    }
  },

  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Forma 31' }])
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
